import React from 'react';
import HeroSection from '../components/herosection';

function Home() {
  return (
    <div> 
      <HeroSection />
    </div>
  );
}
export default Home;